import * as React from 'react';

import BackendIframe from './backend-iframe';

import { FEATURE_SITECORE_HOMEPAGE, isEnabled } from '@utils/features';

import SitecoreMain from 'src/sitecore/wrappers/SitecoreHomepage';

const IndexPage: React.FC = () => {
  return isEnabled(FEATURE_SITECORE_HOMEPAGE) ? (
    <SitecoreMain />
  ) : (
    <BackendIframe />
  );
};

export default IndexPage;
